<template>
  <page-view title="诉求记录查询" left-arrow>
    <template #nav-right>
      <yhbh-select v-model="form.yhbh" @confirm="yhbhChangeHandle" :custom-style="{right: '-16px'}"/>
    </template>
    <van-form 
      :show-error="true"
      :show-error-message="false"
      :scroll-to-error="true"
      :validate-first="true"
      @submit="onSubmit" @failed="onFailed">
      <van-field
        name="isNil"
        label="非系统户"
        input-align="right">
        <template #input>
          <van-switch v-model="form.isNil" size="20" active-value="1" inactive-value="0" @change="handleIsNilChange"/>
        </template>
      </van-field>
      <van-field
        v-model="form.yhbh"
        name="yhbh"
        label="用户编号"
        placeholder="用户编号"
        :disabled="form.isNil == '1' ? true: false"
        :rules="[{ required: true, message: '请填写用户编号' }]"
      />
      <van-field
        name="yhdh"
        v-model="form.yhdh"
        label="联系方式"
        placeholder="联系方式"
        :rules="[{ required: form.isNil == '1' ? true: false, message: '请填写联系方式' }]"
      />
     
      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">查询</van-button>
      </div>
    </van-form>

    <loading-layout ref="loadingLayout" 
      empty-text="未查询到报修记录~"
      @reaty="fetchData">
      <van-cell-group>
        <van-cell 
          v-for="item in list" :key="item.gzdh"
          :title="'工单号【'+(item.ywbh?item.ywbh:item.gzdh)+'】'"
          @click="itemClickHandle(item)">
          <template #label>
            <p>诉求时间：{{item.sqsj}}</p>
            <p>处理进度：{{zfdmFormatter('SQZT', item.gzdzt)}}</p>
          </template>
          <van-button type="primary" size="mini">查看详情</van-button>
        </van-cell>
      </van-cell-group>
    </loading-layout>
  </page-view>
</template>

<script>
import { mapGetters } from 'vuex';

import YhbhSelect from '@/components/YhbhSelect/index.vue';

import {
  zfdmManageService_getZfdmByDmzl
} from '@/api/zfdm'
import { gzdGzsl_selectiveGet } from '@/api/psdmszhwh/zhwhschema/GzdGzslAPI';

export default {
  name: 'bxjlcx',
  components: {
    YhbhSelect
  },
  data() {
    return {
      form: {
        isNil: '0',
        yhbh: '',
        yhdh: ''
      },
      zfdmOptions: {
        'SQZT': [], // 诉求状态
      },
      list: []
    }
  },
  computed: {
    ...mapGetters(['jgbm','czyh'])
  },
  methods: {
    yhbhChangeHandle(yhxx) {
      this.form.isNil = '0';
      this.form.yhbh = yhxx.yhbh;
      this.form.yhdh = yhxx.lxsj || yhxx.lxdh;
      this.form.jgbm = yhxx.jgbm;
      this.form.dybm = yhxx.dybm;
    },
    handleIsNilChange(val) {
      if (val == '1') {
        this.form.yhbh = '非系统用户';
      } else {
        this.form.yhbh = '';
        this.form.yhdh = '';
      }
    },
    onSubmit(values) {
      console.log('submit', values);

      this.fetchData();
    },
    fetchData() {
      let params = {
        yhbh: this.form.yhbh,
        yhdh: this.form.yhdh
      };
      params.jgbm = this.form.jgbm?this.form.jgbm:this.jgbm;
      // params.bxslrid = this.czyh;
      this.$refs['loadingLayout'].setStatus('loading')
      gzdGzsl_selectiveGet(params).then(res => {
        this.list = res.content;
        if (res.content && res.content.length) {
          this.$refs['loadingLayout'].setStatus('success')
        } else {
          this.$refs['loadingLayout'].setStatus('empty')
        }
      }).catch(err => {
        this.$refs['loadingLayout'].setStatus('error')
        this.$toast("报修记录查询失败:"+err.message);
      });
    },
    onFailed({values, errors}) {
      console.log('failed:, Errors:', errors)
      this.$notify({ type: 'danger', message: errors[0].message});
    },

    itemClickHandle(item) {
      console.log(item);
      this.$router.push({path: '/bxjlxq', query: item})
    },
    zfdmFormatter(dmzl, value) {
      let zfdmArr = this.zfdmOptions[dmzl].filter(item => item.dmbh == value);
      if (zfdmArr.length) {
        return zfdmArr[0].dmnr.split("-")[1];
      } 
      return '';
    },
  },
  mounted() {
    
    for(let key in this.zfdmOptions) {
      zfdmManageService_getZfdmByDmzl(key).then(response => {
        this.zfdmOptions[key] = response.content;
      });
    }
  }
}
</script>